import type { FC } from "react";
import FilePreview from "./FilePreview";
import MediaPreview from "./MediaPreview";

type Props = {
  files: readonly File[];
  allowMultipleDocumentsPreview?: boolean;
};

const hasNonThumbnailFile = (files: readonly File[]) =>
  files.some(
    (file) =>
      !["image/", "video/"].some((s) => {
        return file.type.startsWith(s);
      }),
  );

const Preview: FC<Props> = ({ files, allowMultipleDocumentsPreview = false }) => {
  return !hasNonThumbnailFile(files) ? (
    <MediaPreview files={files} />
  ) : (
    <div>
      {allowMultipleDocumentsPreview ? (
        files.map((file) => <FilePreview key={`${file.name}-${file.lastModified}`} file={file} />)
      ) : (
        <FilePreview file={files[0]} />
      )}
    </div>
  );
};
export default Preview;
