import { logException } from "@classdojo/log-client";

type ReaderResult = ArrayBuffer | string;
type ThumbnailGenerator = (file: File, readerResult: ReaderResult) => Promise<string | null>;
type Guard = (file: File, readerResult: ReaderResult) => boolean;

export const generateFrom =
  (...generators: ThumbnailGenerator[]) =>
  async (file: File, readerResult: ReaderResult | null) => {
    if (!readerResult) return null;
    for (const generator of generators) {
      try {
        const result = await generator(file, readerResult);
        if (result) return result;
        // this gets logged as a metric in hooks.ts
        // eslint-disable-next-line no-catch-all/no-catch-all
      } catch (err: any) {
        logException(err, {});
        return null;
      }
    }

    return null;
  };

const makeGenerator =
  (guard: Guard, generator: ThumbnailGenerator): ThumbnailGenerator =>
  (file, result) =>
    guard(file, result) ? generator(file, result) : Promise.resolve(null);

export const heic = makeGenerator(
  (file) => file.name.toLowerCase().endsWith(".heic"),
  async (file) => {
    const { default: heic2any } = await import("heic2any");
    const jpg = await heic2any({ blob: file, toType: "image/jpeg" });
    return URL.createObjectURL(jpg as Blob);
  },
);

export const anyImage = makeGenerator(
  (file) => file.type.startsWith("image/"),
  (_, result) => Promise.resolve(String(result)),
);

export const anyVideo = makeGenerator(
  (file) => file.type.startsWith("video/"),
  (_, result) =>
    new Promise((resolve) => {
      const videoElement = document.createElement("video");
      videoElement.src = String(result);
      videoElement.addEventListener("loadedmetadata", () => {
        // use a canvas to "draw" the frame
        const canvas = document.createElement("canvas");
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        canvas.getContext("2d")?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        // anecdotally, some videos seem to have transparent frames at the beginning?
        // so, we seek to the middle frame and draw that instead (🤷)
        const middleTime = videoElement.duration / 2;
        videoElement.currentTime = middleTime;
        videoElement.addEventListener("seeked", () => {
          // draw the frame onto the canvas
          canvas.getContext("2d")?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL());
        });
      });
    }),
);
