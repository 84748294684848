import { type FC, type MutableRefObject, useRef } from "react";
import { useMediaThumbnail } from "./hooks";
import LOADING from "./loading-mojo.gif";
import styles from "./MediaThumbnail.module.css";

import MinusButton from "./MinusButton";
import { useStore } from "../../state";

type Props = {
  file: File;
};

const MediaThumbnail: FC<Props> = ({ file }) => {
  const store = useStore();
  const element = useRef<HTMLImageElement>(null) as MutableRefObject<HTMLImageElement>;
  useMediaThumbnail(file, element);

  return (
    <picture className={styles.container}>
      <img className={styles.img} src={LOADING} ref={element} alt={file.name} width="40%" height="40%" />
      <MinusButton className={styles.xButton} onClick={() => store.deleteFile(file)} />
    </picture>
  );
};

export default MediaThumbnail;
