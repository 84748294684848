import "regenerator-runtime/runtime"; // checkMp4File needs regenerator-runtime imported first

import * as logClient from "@classdojo/log-client";
import type { UppyFile } from "@uppy/core";
import callApi from "@web-monorepo/infra/callApi";
import { captureException } from "@web-monorepo/telemetry";
import { Mp4FileCheckerError } from "../errors";
import type { UploadedFile } from "../types";

const MP4_OPTIONS = {
  maxWidth: 2000,
  maxHeight: 2000,
  supportedMimeTypes: ["video/mp4"],
  supportedVideoCodecs: [/^avc1\.42e0/, /^avc1\.4d40/, /^avc1\.6400/],
  supportedAudioCodecs: ["mp4a.40.2", "mp4a.40.5"],
};

type UploadInfo<T extends File | UppyFile> = T extends File
  ? UploadedFile
  : Omit<UploadedFile, "file"> & { file: UppyFile };

const getFileType = (file: File | UppyFile) => ("type" in file ? file.type : file.meta.type) ?? "";

const fileToBucketName = (file: File | UppyFile) => {
  const fileType = getFileType(file);
  if (fileType.startsWith("image")) {
    return "photo";
  }
  if (fileType.startsWith("video")) {
    return "video";
  }

  return "file";
};

export const metricLogger = (transport: string) => ({
  success: (metricName: "s3_put" | "transaction") =>
    logClient.sendMetrics([
      {
        metricName: `file_upload.${metricName}`,
        type: "increment",
        value: 1,
        tags: { transport, result: "success" },
      },
    ]),
  failure: (metricName: "s3_put" | "transaction", times: number = 1) => {
    if (times > 0) {
      logClient.sendMetrics([
        {
          metricName: `file_upload.${metricName}`,
          type: "increment",
          value: times,
          tags: { transport, result: "failure" },
        },
      ]);
    }
  },
});

export const getUploadURL = async <T extends File | UppyFile>(
  file: T,
  getBucket: (file: File | UppyFile) => string = fileToBucketName,
): Promise<UploadInfo<T>> => {
  const bucketName = getBucket(file);

  const uploadPutHeaders: Record<string, string> = {};
  const query: Record<string, string> = {
    filename: file.name,
    type: bucketName, // gross
  };

  if (file.type) {
    query.contentType = file.type;
    uploadPutHeaders["Content-Type"] = file.type;
  }

  const fileType = getFileType(file);
  const data = "data" in file ? file.data : (file as File);
  //if video doesn't have correct codec, add "needsTranscode" to query params
  if (fileType.startsWith("video")) {
    try {
      const videoHasApprovedFileType = await import("mp4filechecker").then(({ checkMp4File }) =>
        checkMp4File(data, MP4_OPTIONS),
      );
      if (!videoHasApprovedFileType) {
        query.needsTranscode = "true";
      }
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (err) {
      // We might as well still try to upload? (this was working on my machine when I tried it)
      captureException(new Mp4FileCheckerError(err));
    }
  }

  return await callApi({
    path: "/api/uploadFileURL",
    method: "GET",
    query,
    // Send and receive JSON.
    headers: {
      accept: "application/json",
    },
  }).then(
    (response: {
      body?: {
        path: string;
        urlSmall: string;
        cdnUrl: string;
        _links?: { url: { href: string } };
      };
    }) => {
      const data = response.body;

      if (!data) {
        throw new Error(`BAD RESPONSE - no data: ${JSON.stringify(data)}`);
      }

      if (!data._links) {
        throw new Error(`BAD RESPONSE - no _links: ${JSON.stringify(data)}`);
      }

      if (data && !data.path) {
        throw new Error(`BAD RESPONSE - no path: ${JSON.stringify(data)}`);
      }

      if (data._links && !data._links.url) {
        throw new Error(`BAD RESPONSE - no url: ${JSON.stringify(data)}`);
      }

      return {
        file,
        url: data._links.url.href,
        key: data.path,
        urlSmall: data.urlSmall,
        cdnUrl: data.cdnUrl,
        headers: uploadPutHeaders,
      } as unknown as UploadInfo<T>;
    },
  );
};
