// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconSpinner = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9375 3.55371C12.9375 4.07148 12.5178 4.49121 12 4.49121C10.7652 4.49121 9.5494 4.79575 8.46038 5.37784C7.37136 5.95993 6.44271 6.80162 5.75667 7.82834C5.07064 8.85506 4.6484 10.0351 4.52737 11.264C4.40634 12.4929 4.59024 13.7327 5.06279 14.8735C5.53533 16.0143 6.28194 17.021 7.23648 17.8044C8.19101 18.5877 9.32401 19.1236 10.5351 19.3645C11.7462 19.6054 12.998 19.5439 14.1797 19.1855C15.3613 18.827 16.4364 18.1827 17.3095 17.3095C17.6756 16.9434 18.2692 16.9434 18.6353 17.3095C19.0015 17.6756 19.0015 18.2692 18.6353 18.6353C17.5442 19.7265 16.2007 20.5318 14.724 20.9797C13.2472 21.4277 11.6828 21.5045 10.1693 21.2035C8.65579 20.9024 7.23988 20.2327 6.04699 19.2538C4.8541 18.2748 3.92106 17.0167 3.33051 15.591C2.73997 14.1653 2.51014 12.616 2.6614 11.0802C2.81266 9.54449 3.34033 8.06975 4.19767 6.78665C5.05501 5.50354 6.21556 4.45168 7.57651 3.72424C8.93747 2.99679 10.4568 2.61621 12 2.61621C12.5178 2.61621 12.9375 3.03594 12.9375 3.55371Z"
      />
    </svg>
  );
};
