// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconRefreshClockwise = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9175 21.1675C11.9475 21.1675 11.9775 21.1675 12.0037 21.1675C14.0437 21.1675 15.9712 20.3838 17.4299 18.9513C18.9112 17.5 19.7437 15.5613 19.7662 13.4838C19.7699 12.9663 19.3574 12.5425 18.8399 12.535C18.3337 12.5388 17.8987 12.9438 17.8912 13.4613C17.8724 15.0363 17.2462 16.5063 16.1212 17.6088C14.9962 18.7075 13.4999 19.3188 11.9362 19.2888C10.3612 19.27 8.8912 18.6438 7.7887 17.5188C6.68995 16.3938 6.09371 14.9088 6.10871 13.3338C6.14246 10.1613 8.69247 7.59625 11.835 7.51C11.85 7.51 11.9212 7.51375 12.0187 7.51375C12.315 7.525 12.4687 7.87751 12.2699 8.10251L10.3949 10.21C10.0499 10.5963 10.0837 11.1888 10.4737 11.5338C10.6537 11.6913 10.8749 11.77 11.0962 11.77C11.3549 11.77 11.6137 11.665 11.7974 11.455L15.2925 7.525C15.5925 7.1875 15.6074 6.67751 15.3299 6.32126L12.0787 2.18875C11.7599 1.78 11.1674 1.71251 10.7624 2.03126C10.3574 2.35001 10.2862 2.94251 10.605 3.34751L11.9549 5.06501C12.1349 5.29376 11.9775 5.6275 11.6887 5.635H11.685C7.58621 5.8 4.27871 9.16001 4.23371 13.3075C4.18871 17.5975 7.63496 21.1188 11.9175 21.1675Z" />
    </svg>
  );
};
