// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconDojoTutor = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.4006 5.12194C20.1793 4.71694 19.2831 3.53194 18.2781 2.99944C15.8331 1.72069 11.7306 1.35694 10.0806 1.25944C9.49184 1.22569 8.94434 1.50694 8.62559 1.99069C5.80934 6.28819 3.54809 11.8532 3.40184 13.5032C3.33434 14.2682 3.79184 14.9619 4.52309 15.1982L7.03934 16.0044C7.24184 16.0682 7.33559 16.2969 7.23809 16.4844L5.20559 20.4107L5.16809 20.4932C5.16059 20.5157 5.15684 20.5344 5.14934 20.5569C4.79309 21.2244 4.92809 21.9482 4.95809 22.0869C5.07434 22.6194 5.66684 22.9307 6.25934 22.6194L16.9131 17.0844C17.6931 16.6794 18.1956 15.8394 18.0681 14.9694C18.0568 14.8944 18.0418 14.8194 18.0231 14.7407C17.8356 14.0282 17.3031 13.4582 16.6056 13.2257L15.2256 12.7607C14.9893 12.6819 14.9143 12.3857 15.0831 12.2057L20.1231 7.08319C20.6406 6.55069 20.7531 5.76319 20.4006 5.11819V5.12194ZM8.39309 17.9694L9.68309 15.4794C9.78059 15.2919 9.68309 15.0632 9.48434 14.9994L5.39684 13.6869C5.22809 13.6307 5.12684 13.4582 5.16809 13.2857C5.53934 11.6807 7.35809 7.10569 9.91934 3.13819C9.98684 3.03319 10.1068 2.97319 10.2306 2.98069C11.4981 3.06694 14.5431 3.34444 16.6731 4.15069C16.8868 4.23319 16.9656 4.49569 16.8268 4.67944L11.0068 12.3744C10.8606 12.5657 10.9543 12.8432 11.1868 12.9069L14.5018 13.8482C14.7868 13.9307 14.8393 14.3094 14.5881 14.4669L8.63684 18.1944C8.49059 18.2882 8.31434 18.1269 8.39309 17.9732V17.9694Z" />
    </svg>
  );
};
