import type { FC } from "react";
import styles from "./FileSize.module.css";

type Props = {
  children: number;
};

const toLabel = ({ b, kb, mb, gb }: Record<"b" | "kb" | "mb" | "gb", number>) => {
  if (gb >= 1) {
    return `${gb.toFixed(2)} gb`;
  }

  if (mb >= 1) {
    return `${mb.toFixed(2)} mb`;
  }

  if (kb >= 1) {
    return `${kb.toFixed(2)} kb`;
  }

  return `${b} b`;
};

const FileSize: FC<Props> = ({ children: sizeInBytes }) => {
  const sizeInKb = sizeInBytes / 1024;
  const sizeInMb = sizeInKb / 1024;
  const sizeInGb = sizeInMb / 1024;

  const label = toLabel({
    b: sizeInBytes,
    kb: sizeInKb,
    mb: sizeInMb,
    gb: sizeInGb,
  });

  return <span className={styles.fileSize}>{label}</span>;
};

export default FileSize;
