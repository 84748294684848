import type { FC } from "react";
import { IconAttach } from "@web-monorepo/dds-icons";
import XButton from "../XButton";
import { useStore } from "../state";
import FileSize from "./FileSize";
import styles from "./FilePreview.module.css";

type Props = {
  file: File | undefined;
};

const FilePreview: FC<Props> = ({ file }) => {
  const store = useStore();
  if (!file) return null;

  return (
    <article className={styles.container}>
      <div className={styles.thumbnail}>
        <IconAttach />
      </div>
      <div className={styles.metadata}>
        <h2 className={styles.label}>{file.name}</h2>
        <FileSize>{file.size}</FileSize>
      </div>
      <XButton className={styles.xButton} onClick={() => store.deleteFile(file)} />
    </article>
  );
};

export default FilePreview;
